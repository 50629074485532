<template>
  <transition name="toggle">
    <div
      v-show="value"
      class="dialog-mask"
    >
      <v-flex
        class="d-flex justify-center mb-6">
        <material-card
          max-width="100%"
          color="green"
        >
          <v-card
            class="hide-overflow"
          >
            <v-toolbar
              card
            >
              <v-icon>mdi-tools</v-icon>
              <v-toolbar-title class="font-weight-light">设备组名称</v-toolbar-title>
              <v-spacer/>
              <v-btn
                color="success"
                fab
                small
                @click="edit"
              >
                <v-icon v-if="isEditing">mdi-close</v-icon>
                <v-icon v-else>mdi-pencil</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-text-field
                v-model="editName"
                :disabled="!isEditing"
                color="white"
                label="Name"
              />
            </v-card-text>
            <v-divider/>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                :disabled="!isEditing"
                color="success"
                @click="save"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
          <!--<v-flex-->
          <!--  xs12-->
          <!--  md6-->
          <!--&gt;-->
          <!--  <v-text-field-->
          <!--    v-model="editDialog.devicegroupname"-->
          <!--    label="设备组名称"-->
          <!--    readonly-->
          <!--  />-->
          <!--</v-flex>-->
          <!--<v-flex-->
          <!--  xs12-->
          <!--  md6-->
          <!--&gt;-->
          <!--  <v-text-field-->
          <!--    v-model="editDialog.newdevicegroupname"-->
          <!--    label="新设备组名称"-->
          <!--    required-->
          <!--    @keyup.enter="updateDeviceGroup"-->
          <!--    @blur="updateDeviceGroup"-->
          <!--  />-->
          <!--</v-flex>-->
          <v-container
            fill-height
            fluid
            grid-list-xl
            style="padding-top: 0"
          >
            <v-flex
              xs12
              md6
              style="padding: 0"
            >
              <v-text-field
                v-model="editleftsearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-flex>
            <v-flex
              xs12
              md1/>
            <v-flex
              xs12
              md6
              style="padding: 0"
            >
              <v-text-field
                v-model="editrightsearch"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              />
            </v-flex>
          </v-container>
          <v-container
            fill-height
            fluid
            grid-list-xl
            style="padding-top: 0"
          >
            <v-layout
              justify-center
              wrap
            >
              <v-flex
                xs12
                md5
              >
                <material-card>
                  <v-data-table
                    :headers="editleftheaders"
                    :items="editleftitems"
                    :search="editleftsearch"
                    :pagination.sync="editleftpagination"
                    :rows-per-page-items="[editleftpagination.rowsPerPage]"
                    v-model="editleftselected"
                    class="elevation-3 "
                    select-all
                  >
                    <template
                      v-slot:items="rowsprops"
                    >
                      <tr>
                        <td>
                          <v-checkbox
                            v-model="rowsprops.selected"
                            color="#42a5f5"
                            hide-details
                          />
                        </td>
                        <td>{{ rowsprops.item.devicename }}</td>
                        <td>{{ rowsprops.item.devicemodel }}</td>
                        <td style="padding-top: 0;padding-bottom: 0">
                          <v-btn
                            slot="activator"
                            class="v-btn--small"
                            color="#42a5f5"
                            icon
                            @click.stop="addeditright(rowsprops.item)"
                          >
                            <v-icon>mdi-chevron-right</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                </material-card>
              </v-flex>
              <v-flex
                xs12
                md1
                style="padding: 0"
              >
                <v-container
                  fluid
                  grid-list-xl
                >
                  <v-flex
                    xs12
                    md5
                  >
                    <v-btn
                      slot="activator"
                      icon
                      color="#42a5f5"
                      @click.stop="multiaddeditright(editleftselected)"
                    >
                      <v-icon>mdi-chevron-double-right</v-icon>
                    </v-btn>
                  </v-flex>
                  <v-flex
                    xs12
                    md5
                  >
                    <v-btn
                      slot="activator"
                      icon
                      color="#42a5f5"
                      @click.stop="multiaddeditleft(editrightselected)"
                    >
                      <v-icon>mdi-chevron-double-left</v-icon>
                    </v-btn>
                  </v-flex>
                </v-container>
              </v-flex>
              <v-flex
                xs12
                md5
              >
                <material-card>
                  <v-data-table
                    :headers="editrightheaders"
                    :items="editrightitems"
                    :search="editrightsearch"
                    :pagination.sync="editrightpagination"
                    :rows-per-page-items="[editrightpagination.rowsPerPage]"
                    v-model="editrightselected"
                    class="elevation-3 "
                    select-all
                  >
                    <template
                      v-slot:items="rowsprops"
                    >
                      <td>
                        <v-checkbox
                          v-model="rowsprops.selected"
                          color="#42a5f5"
                          hide-details
                        />
                      </td>
                      <td
                        style="padding-top: 0;padding-bottom: 0"
                        class="text-xs-left">
                        <v-btn
                          slot="activator"
                          class="v-btn--small"
                          color="#42a5f5"
                          icon
                          @click.stop="addeditleft(rowsprops.item)"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </td>
                      <td>{{ rowsprops.item.devicename }}</td>
                      <td>{{ rowsprops.item.devicemodel }}</td>
                    </template>
                  </v-data-table>
                </material-card>
              </v-flex>
            </v-layout>
          </v-container>
          <v-container py-2>
            <v-flex
              xs12
              text-xs-right
              style="margin-right:20px"
            >
              <!--<v-btn-->
              <!--  class="mx-1 font-weight-light"-->
              <!--  color="success"-->
              <!--  @click="submit"-->
              <!--&gt;-->
              <!--  保存-->
              <!--</v-btn>-->
              <v-btn
                class="mx-1 font-weight-light"
                color="success"
                @click="close"
              >
                关闭
              </v-btn>
            </v-flex>
          </v-container>
        </material-card>
      </v-flex>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'DeviceGroupEdit',
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    editName: '',
    isEditing: false,
    editleftselected: [],
    editrightselected: [],
    editleftsearch: '',
    editrightsearch: '',
    editDialog: {
      devicegroupname: '',
      newdevicegroupname: '',
      id: ''
    },
    editleftpagination: { page: 1, rowsPerPage: 5, totalItems: 0, sortBy: '' },
    editrightpagination: { page: 1, rowsPerPage: 5, totalItems: 0, sortBy: '' },
    editleftheaders: [
      { sortable: true, text: '设备名称', value: 'devicename', width: '40%' },
      { sortable: true, text: '设备型号', value: 'devicemodel', width: '40%' },
      { sortable: false, text: '', value: '', width: '8%' }
    ],
    editleftitems: [],
    editrightheaders: [
      { sortable: false, text: '', value: '', width: '5%' },
      { sortable: true, text: '设备名称', value: 'devicegroupname', width: '40%' },
      { sortable: true, text: '设备型号', value: 'devicemodel', width: '40%' }
    ],
    editrightitems: [],
    rowsprops: {}
  }),
  computed: {
  },
  created () {
  },
  methods: {
    renew (rowsprops) {
      // console.log(rowsprops)
      this.rowsprops = rowsprops
      this.editDialog.id = rowsprops.item.id
      this.editDialog.devicegroupname = rowsprops.item.devicegroupname
      this.editDialog.newdevicegroupname = ''
      this.editName = this.editDialog.devicegroupname
      this.getGroupDevicesList(this.editDialog.id)
      this.editnoGroupDeviceList()
    },
    getGroupDevicesList (id) {
      // console.log('getGroupDevicesList(): ', id)
      this.editrightitems = []
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/group/getGroupDevicesList?groupId=' + id, this.$authHeaders())
        .then(result => {
          let res = result.data
          // console.log('getGroupDevicesList(): ', res)
          if (res.code === 0) {
            for (let i = 0; i < res.detail.list.length; i++) {
              let obj = {
                'id': res.detail.list[i].id,
                'devicename': res.detail.list[i].description,
                'createtime': this.$getDateTimeString(res.detail.list[i].timestamp),
                'devicemodel': res.detail.list[i].serialCode,
                'index': i + 1
              }
              this.editrightitems.push(obj)
            }
            this.editrightpagination.totalItems = res.detail.list.length
            this.editrightpagination.rowsPerPage = 5
            this.editrightpagination.page = 1
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    updateDeviceGroup () {
      if (this.editDialog.newdevicegroupname === '') {
        this.$snackbar.info('新设备组名称不能为空')
        return
      }
      let formdata = new FormData()
      formdata.append('groupId', this.editDialog.id)
      formdata.append('groupName', this.editDialog.devicegroupname)
      formdata.append('newName', this.editDialog.newdevicegroupname)
      // console.log('formdata: ', formdata)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/group/modifyGroup', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.$snackbar.success(res.message)
            this.editDialog.devicegroupname = this.editDialog.newdevicegroupname
          } else {
            this.$snackbar.err(res.message)
          }
        }, (error) => {
          this.$httpCatch(error)
        })
    },
    editnoGroupDeviceList () {
      this.editleftitems = []
      this.$http
        .get(this.$store.state.app.url + '/mediaSystem/device/noGroupDeviceList', this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            for (let i = 0; i < res.detail.list.length; i++) {
              let obj = {
                'id': res.detail.list[i].id,
                'devicename': res.detail.list[i].description,
                'uploadtime': this.$getDateString(res.detail.list[i].timestamp),
                'devicemodel': res.detail.list[i].serialCode,
                'index': i + 1
              }
              this.editleftitems.push(obj)
            }
            this.editleftpagination.totalItems = res.detail.list.length
            this.editleftpagination.rowsPerPage = 5
            this.editleftpagination.page = 1
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    addeditright (obj) {
      for (let i = 0; i < this.editleftitems.length; i++) {
        if (this.editleftitems[i].id === obj.id) {
          this.editleftitems.splice(i, 1)
          let rightobj = {
            'id': obj.id,
            'devicename': obj.devicename,
            'uploadtime': this.$getDateString(obj.uploadtime),
            'devicemodel': obj.devicemodel
          }
          let arr = []
          arr.unshift(obj.id)
          this.addGroupDevices(this.editDialog.devicegroupname, arr)
          // this.deviceGroupDialog.rightids.push(obj.id)
          this.editrightitems.unshift(rightobj)
          this.editrightpagination.totalItems++
          this.editleftpagination.totalItems--
        }
      }
    },
    addeditleft (obj) {
      for (let i = 0; i < this.editrightitems.length; i++) {
        if (this.editrightitems[i].id === obj.id) {
          this.editrightitems.splice(i, 1)
          let leftobj = {
            'id': obj.id,
            'devicename': obj.devicename,
            'uploadtime': this.$getDateString(obj.uploadtime),
            'devicemodel': obj.devicemodel
          }
          // for (let j = 0; j < this.deviceGroupDialog.rightids.length; j++) {
          //   if (this.deviceGroupDialog.rightids[j] === obj.id) {
          //     this.deviceGroupDialog.rightids.splice(j, 1)
          //   }
          // }
          let arr = []
          arr.unshift(obj.id)
          this.removeGroupDevices(this.editDialog.devicegroupname, arr)
          this.editleftitems.unshift(leftobj)
          this.editrightpagination.totalItems--
          this.editleftpagination.totalItems++
        }
      }
    },
    multiaddeditright (arr) {
      let rightids = []
      for (let i = 0; i < arr.length; i++) {
        let rightobj = {
          'id': arr[i].id,
          'devicename': arr[i].devicename,
          'uploadtime': this.$getDateString(arr[i].uploadtime),
          'devicemodel': arr[i].devicemodel
        }
        for (let j = 0; j < this.editleftitems.length; j++) {
          if (this.editleftitems[j].id === arr[i].id) {
            this.editleftitems.splice(j, 1)
          }
        }
        rightids.unshift(arr[i].id)
        this.editrightitems.unshift(rightobj)
        this.editrightpagination.totalItems++
        this.editleftpagination.totalItems--
      }
      this.addGroupDevices(this.editDialog.devicegroupname, rightids)
    },
    multiaddeditleft (arr) {
      let leftids = []
      for (let i = 0; i < arr.length; i++) {
        let leftobj = {
          'id': arr[i].id,
          'devicename': arr[i].devicename,
          'uploadtime': this.$getDateString(arr[i].uploadtime),
          'devicemodel': arr[i].devicemodel
        }
        for (let j = 0; j < this.editrightitems.length; j++) {
          if (this.editrightitems[j].id === arr[i].id) {
            this.editrightitems.splice(j, 1)
          }
        }
        leftids.unshift(arr[i].id)
        this.editleftitems.unshift(leftobj)
        this.editrightpagination.totalItems--
        this.editleftpagination.totalItems++
      }
      this.addGroupDevices(this.editDialog.devicegroupname, leftids)
    },
    addGroupDevices (groupName, deviceIds) {
      let formdata = new FormData()
      formdata.append('groupName', groupName)
      formdata.append('deviceIds[]', deviceIds)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/group/addGroupDevices', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.$snackbar.success(res.message)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    removeGroupDevices (groupName, deviceIds) {
      let formdata = new FormData()
      formdata.append('groupName', groupName)
      formdata.append('deviceIds[]', deviceIds)
      this.$http.post(this.$store.state.app.url + '/mediaSystem/group/removeGroupDevices', formdata, this.$authHeaders())
        .then(result => {
          let res = result.data
          if (res.code === 0) {
            this.$snackbar.success(res.message)
          } else {
            this.$snackbar.error(res.message)
          }
        }).catch(error => {
          this.$httpCatch(error)
        })
    },
    close () {
      if (this.rowsprops.item.devicegroupname !== this.editDialog.devicegroupname) {
        this.rowsprops.item.devicegroupname = this.editDialog.devicegroupname
      }
      this.$emit('viewClosed')
    },
    edit () {
      this.isEditing = !this.isEditing
      this.editName = this.editDialog.devicegroupname
    },
    save () {
      // console.log('save()')
      this.isEditing = !this.isEditing
      this.editDialog.newdevicegroupname = this.editName
      this.updateDeviceGroup()
    }
  }
}
</script>

<style scoped lang="scss">
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.1s;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0;
  }
  .dialog-mask {
    background-color: #eeeeee;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
    display: table;
    animation-duration: 0s;
  }
</style>
